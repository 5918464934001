<template>
    <div class="container-fluid px-0 height-full">
        <div class="rounded-0 px-0">
            <div id="wrapper">
                <SideBarMenu/>
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <NavBar/>
                        <div class="container-fluid bg-white">
                            <slot></slot>
                        </div>
                    </div>
                    <footer class="sticky-footer bg-light-grey mt-3">
                        <div class="container my-auto">
                            <div class="copyright text-center my-auto">
                                <span>Copyright &copy; Lifebox {{ getCurrentYear }}</span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'Auth',

        computed: {
            getCurrentYear: function() {
                return moment().format('YYYY')
            }
        },

        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>

    #content-section {
        padding-top: 20px;
        overflow-y: auto;
        -webkit-box-shadow: -12px 0px 54px -16px rgba(0,0,0,0.3);
        -moz-box-shadow: -12px 0px 54px -16px rgba(0,0,0,0.3);
        box-shadow: -12px 0px 54px -16px rgba(0,0,0,0.3);
    }

    #section-wrapper {
        padding-top: 55px;
    }
</style>
