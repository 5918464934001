<template>
    <div class="window" :class="[classes]">
        <div
            class="window-item"
            v-for="step in steps"
            :key="step"
        >
            <slot :name="`${step}`" v-if="active === step"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                validator(v) {
                    return v === null
                        || typeof v === 'string'
                        || typeof v === 'number'
                },
            },

            steps: {
                type: Array,
                validator(v) {
                    return v.every(i => {
                        return typeof i === 'string'
                            || typeof i === 'number'
                    })
                }
            },

            classes: {
                type: String,
                default: ''
            }
        },

        computed: {
            active() {
                return this.value === null ? this.steps[0] : this.value
            }
        },

        methods: {
            showNext() {
                const c = this.steps.findIndex(e => e === this.active)
                const i = Math.min(this.steps.length - 1, c + 1)
                return this.show(this.steps[i])
            },

            showPrevious() {
                const c = this.steps.findIndex(e => e === this.active)
                const i = Math.max(0, c - 1)
                return this.show(this.steps[i])
            },

            show(step) {
                if (this.steps.indexOf(step) === -1) return

                this.$emit('input', step)
                this.$emit('change', step)
                window.scrollTo(0, 0)

                return step
            },
        }
    }
</script>
