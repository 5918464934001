<template>
    <div class="container-fluid px-0">
		<div class="rounded-0 px-0">
			<slot></slot>
		</div>
    </div>
</template>

<script>
    export default {
        name: 'Guest'
    }
</script>
