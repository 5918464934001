<template>
    <div class="form-group">
        <label :for="inputId" v-if="label.length">{{ label }}</label>
        <div class="input-group">
            <div class="custom-file">
                <input type="file" :class="[{ 'is-invalid': errors.length }, 'custom-file-input', classes]" :multiple="multiple" :id="inputId" :accept="fileTypes" @change="onFileSelect" ref="fileupload">
                <label :for="inputId" class="custom-file-label text-truncate">{{ textFile }}</label>
            </div>
        </div>
        <button type="button" :class="['mt-2 btn btn-default btn-block btn-sm text-center', clearButtonColor]" @click="clear()">Clear Files</button>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: ''
            },

            value: {
                validator(v) {
                    return typeof v === 'object'
                        || typeof v === 'string'
                        || v === null
                }
            },

            original: {
                type: Array,
                default: () => ([])
            },
            
            fileTypes: {
                type: String,
                default: ''
            },

            clearButtonColor: {
                type: String,
                default: 'bg-white'
            },

            classes: {
                type: String,
                default: ''
            },

            errors: {
                type: Array,
                default: () => ([])
            },

            multiple: {
                type: Boolean,
                default: false
            }
        },

        data: () => ({
            inputId: null,
            textFile: 'Select Files',
            files: []
        }),

        methods: {
            fileLabel() {
                let files = []

                if (this.value) {
                    files = this.value
                } else {
                    files = this.files
                }
                if (this.multiple) {
                    let label = "";

                    if (files.length) {
                        let total = files.length - 1;

                        for (let field in this.value) {
                            if (! isNaN(field)) {
                                label += files[field].name;

                                if (field != total) {
                                    label +=  ', ';
                                }
                            }
                        }
                        return label;
                    } else {
                        return "Select Files";
                    }
                } else {
                    return this.value === null ? `Choose File` : this.value.name || this.value
                }
            },
            onFileSelect(event) {
                const input = event.target
                if (input.files) {
                    let isFileSizeOk = true

                    this.files = input.files

                    for (let file in this.files) {
                        if (this.files[file].size > 10615705) {
                            isFileSizeOk = false
                        }
                    }

                    if (! isFileSizeOk) {
                        this.$toast.error('The file size may not be greater than 10mb.', 'Opss!', { position: 'topRight' })
                    } else {
                         if (this.multiple) {
                            this.$emit('input', this.files)
                            this.$emit('change', this.files)
                        } else {
                            this.$emit('input', this.files[0])
                            this.$emit('change', this.files[0])
                        }

                        setTimeout(function(){
                            this.textFile = this.fileLabel()
                        }.bind(this), 100);
                    }
                }
            },
            clear () {
                const input = this.$refs.fileupload;
                input.type = 'text';
                input.type = 'file';

                this.files = [];

                this.$emit('clear', true)

                this.textFile = "Select Files"
            },
        },

        beforeMount() {
            this.inputId = this.random().toString(36).substring(length)
        },
        mounted() {
            if (this.original.length) {
                let total = this.original.length - 1;
                let label = ""
                this.original.forEach(function(name, index){
                    label += name;

                    if (index != total) {
                        label +=  ', ';
                    }
                }.bind(this))

                this.textFile = label
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bg-gray {
        background: #5a6268;
        color: #fff;
    }
</style>

