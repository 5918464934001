<template>
    <table :class="['table', theme, classes]">
        <thead class="text-center">
            <tr>
                <th v-if="!!$scopedSlots.selector"><input type="checkbox"></th>
                <th v-for="(head, i) in heads" :key="i" v-html="head" class="vert-center"></th>
                <th v-if="!!$scopedSlots.actions" class="actions">&nbsp;</th>
            </tr>
        </thead>
        <tbody class="text-center">
            <tr 
                v-for="(row, i) in rows" 
                :key="i" 
                :class="[ i % 2 ? stripedColor : 'table-default' ]"
            >
                <td 
                    v-if="!!$scopedSlots.selector"
                    align="center" 
                    class="vert-center"
                >
                    <slot name="selector" :row="row"></slot>
                </td>

                <slot name="body" :row="row">
                    <td 
                        v-for="(data, k) in row" 
                        v-html="data" 
                        :key="k"
                        align="center" 
                        class="vert-center"
                    >
                    </td>
                </slot>

                <td 
                    v-if="!!$scopedSlots.actions"
                    align="center" 
                    class="vert-center"
                    :width="actionButtonsWidth"
                >
                    <slot name="actions" :row="row"></slot>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import jQuery from 'jquery'
    import 'datatables.net'
    import 'datatables.net-dt'
    import 'datatables.net-responsive'

    export default {
        props: {
            heads: {
                type: Array,
                default: () => ([])
            },

            rows: {
                type: Array,
                default: () => ([])
            },

            customColumns: {
                type: Array,
                default: () => ([])
            },

            customSorting: {
                type: Array,
                default: () => ([])
            },

            enablePaginate: {
                type: Boolean,
                default: false
            },

            enableOrdering: {
                type: Boolean,
                default: false
            },

            searchInputId: {
                type: String,
                default: ''
            },

            classes: {
                type: String,
                default: ''
            },

            actionButtonsWidth: {
                type: String,
                default: '20%'
            },

            theme: {
                type: String,
                default: 'table-striped table-bordered'
            },

            stripedColor: {
                type: String,
                default: 'table-default'
            }
        },

        data: () => ({
            elem: null,
            table: null,
        }),

        watch: {
            rows(val) {
                if (val.length) {
                    this.table.destroy();
                    this.$nextTick(() => {
                        this.dataTable()
                    });
                }

            }
        },

        methods: {
            dataTable() {
                const { enablePaginate, enableOrdering } = this
                this.elem = jQuery(this.$el)

                let searchId = this.searchInputId;

                this.table = this.elem.DataTable({
                    destroy: true,
                    sDom: 'ltipr',
                    bLengthChange: false,
                    shownEntries: false,
                    bInfo: false,
                    pageLength : 10,
                    paging: enablePaginate,
                    ordering: enableOrdering,
                    pagingType: "simple_numbers",
                    aoColumnDefs: this.customColumns,
                    aaSorting: this.customSorting,
                    oLanguage: {
                        oPaginate: {
                            sNext: '<i class="fa fa-angle-double-right"></i>',
                            sPrevious:'<i class="fa fa-angle-double-left"></i>'
                        }
                    }
                })

                if (searchId) {
                    let table = this.table

                    jQuery(searchId).on( 'keyup', function () {
                        table.search( this.value ).draw();
                    });
                }
            }
        },

        mounted() {
            this.dataTable()
        },
    }
</script>

<style lang="scss" scoped>
    .dataTables_paginate {
        bottom: 75px;
        left: 0;
    }

    .actions {
        min-width: 100px;
    }

    .vert-center {
        vertical-align: middle;
    }

    .table {
        width: 100% !important;
    }
</style>
