<template>
	<div>
		<router-view></router-view>
		<vue-progress-bar></vue-progress-bar>
	</div>
</template>

<script>
	export default {
		name: 'App',
	}
</script>