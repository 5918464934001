<template>
    <!-- Topbar -->
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
                <a 
                    class="nav-link dropdown-toggle" 
                    href="#" 
                    id="userDropdown" 
                    role="button" 
                    data-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false"
                >
                    <span class="mr-2 d-none d-lg-inline text-gray-600 small">Administrator</span>
                </a>

                <!-- Dropdown - User Information -->
                <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                    <router-link class="dropdown-item" :to="{ name: 'settings' }">
                        <i class="fa fa-cog mr-2 text-gray-400"></i>
                        Settings
                    </router-link>
                    <div class="dropdown-divider"></div>
                    <a 
                        class="dropdown-item" 
                        href="#" 
                        @click="() => { $store.dispatch('auth/logout') }"
                    >
                        <i class="fa fa-sign-out mr-2 text-gray-400"></i>
                        Logout
                    </a>
                </div>
            </li>
        </ul>
    </nav>
    <!-- End of Topbar -->
</template>

<script>
    export default {
        name: "NavBar"
    }
</script>

<style lang="scss" scoped>
    .dropdown-menu {
        &.large-dropdown {
            width: 400px;
            .card {
                .card-body {
                    max-height: 500px;
                    overflow-y: auto;
                }
            }
        }
    }

    .notification-template {
        cursor: pointer;
        text-decoration: none;
        color: #000;

        &:not(:first-child) {
            border-top: 1px solid #dee2e6;
        }

        &:hover {
            background: #EAEEF2;
        }
    }

    .stop-impersonate-btn {
        min-width: 20% !important; 
        border-radius: initial !important;
    }
</style>
