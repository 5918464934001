<template>
    <div :class="[`card border-left-${color} shadow-sm h-100 py-2`]">
        <div class="card-body">
            <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                    <div :class="[`text-xs font-weight-bold text-${color} text-uppercase mb-1`]">{{ title }}</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                        <slot></slot>
                    </div>
                </div>
                <div class="col-auto">
                    <i :class="[`fa fa-${icon} fa-2x text-gray-300`]"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: 'Overview Card'
            },

            color: {
                validator(v) {
                    return ['success', 'warning', 'info', 'primary', 'danger'].indexOf(v) !== -1
                }
            },

            icon: {
                type: String,
                default: 'briefcase'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        cursor: pointer;
    }
</style>
