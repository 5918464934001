<template>
    <div style="overflow:hidden;">
        <div id="clock"></div>
    </div>
</template>

<script>
    import jQuery from 'jquery'
    import moment from 'moment'
    import 'eonasdan-bootstrap-datetimepicker'

    export default {
        props: {
            value: {
                validator(value) {
                    return value === null || typeof value === 'string'
                }
            },

            placeholder: {
                type: String,
                default: null
            }
        },

        data: () => ({
            elem: null,
            dp: null
        }),

        methods: {
            onChange(event) {
                let format = event.date ? event.date.format('LT') : null
                this.$emit('change', format)
                this.$emit('input', format)
            }
        },

        mounted() {
            this.elem = jQuery(this.$el)
            this.elem.datetimepicker({
                format: 'LT',
                stepping: '15',
                widgetPositioning: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                inline: true,
                sideBySide: true,
                icons: {
                    up: 'fa fa-chevron-up',
                    down: 'fa fa-chevron-down'
                }
            })

            this.dp = this.elem.data('DateTimePicker')
            this.dp.date(moment(this.value, 'LT'))
            this.elem.on('dp.change', this.onChange)

            let format = this.dp.date() ? this.dp.date().format('LT') : null
            this.$emit('change', format)
            this.$emit('input', format)
        },

        beforeDestroy() {
            if (this.dp) {
                this.dp.destroy()
                this.dp = null
                this.elem = null
            }
        }
    }
</script>

<style>
    .datepicker table thead tr:first-child {
        width: 100%;
        background: #e6e6e6;
    }
    .datepicker table thead tr:nth-child(1) th {
        padding: 5px 0;
    }
    .datepicker table thead tr:nth-child(2) th {
        padding: 10px 2px;
    }
    .datepicker table tbody tr td {
        padding: 18.4px 5px;
        margin: 5px;
    }
    .datepicker table {
        border: 1px solid #d6d6d6;
    }
</style>
