<template>
    <button
        :type="type"
        class="btn rounded-10"
        :class="[`btn-${color}`, block ? 'btn-block' : null, `btn-${size}`, classes]"
        :disabled="loading"
    >
        <slot v-if="! loading"></slot>
        <i class="fa fa-circle-o-notch fa-spin fa-fw" v-else></i>
    </button>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'button'
            },

            color: {
                type: String,
                default: 'primary',
                validator(v) {
                    return [
                        'success',
                        'danger',
                        'primary',
                        'info',
                        'light',
                        'dark',
                        'warning',
                        'secondary',
                        'bidcrete-green',
                        'outline-success',
                        'outline-danger',
                        'outline-warning',
                        'outline-dark',
                        'outline-info',
                        'outline-primary',
                        'outline-light',
                        'outline-secondary',
                    ].indexOf(v) !== -1
                }
            },

            block: {
                type: Boolean,
                default: false
            },

            loading: {
                type: Boolean,
                default: false
            },

            size: {
                type: String,
                default: 'md',
                validator(v) {
                    return ['sm', 'lg', 'md'].indexOf(v) !== -1
                }
            },

            classes: {
                type: String,
                default: ''
            }
        },
    }
</script>
