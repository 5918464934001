import Vue from 'vue'
import store from '@/stores'
import { GuestMiddleware, AuthMiddleware } from '@/Middlewares'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    mode: 'history',
    routes: [
        /**
         * Routes for guest pages
         */
        ...GuestMiddleware([
            {
                path: '/',
                name: 'login',
                component: () => import('@/views/Login')
            },
        ]),

        /**
         * Routes for authenticated pages
         */
        ...AuthMiddleware([
            {
                path: '',
                redirect: '/home',
            },
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/Home')
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('@/views/Users')
            },
            {
                path: '/retailers',
                name: 'retailers',
                component: () => import('@/views/Retailers')
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () => import('@/views/Notifications')
            },
            {
                path: '/settings',
                name: 'settings',
                component: () => import('@/views/Settings')
            },
            {
                path: '/users/update/:userID',
                name: 'update-user',
                component: () => import('@/views/forms/UpdateUser')
            },
            {
                path: '/users/create',
                name: 'create-user',
                component: () => import('@/views/forms/CreateUser')
            },
            {
                path: '/retailers/update/:retailerID',
                name: 'update-retailer',
                component: () => import('@/views/forms/UpdateRetailer')
            },
            {
                path: '/retailers/create',
                name: 'create-retailer',
                component: () => import('@/views/forms/CreateRetailer')
            }
        ]),
    ]
})

router.beforeEach(async (to, from, next) => {
    if (store.getters['auth/token'] && !store.getters['auth/user']) {
        await store.dispatch('auth/fetchUser')
    }
    next()
})

export default router