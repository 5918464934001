import Vue from 'vue'
import axios from 'axios'
import VueWait from 'vue-wait'
import router from './router'
import store from './stores'
import App from './App.vue'

import VueProgressBar from 'vue-progressbar'
import VueIziToast from 'vue-izitoast'

import './Components'
import 'bootstrap'

/* Theme stylesheet */
import '@/assets/sass/app.scss'

axios.defaults.baseURL = process.env.VUE_APP_API_HOST || 'http://localhost:8080'
Vue.config.productionTip = false

Vue.use(VueWait)
Vue.use(VueIziToast)
Vue.use(VueProgressBar, {
    color: '6c63ff',
    failedColor: 'red',
    height: '2px'
})

const app = new Vue({
    router,
    store,
    render: h => h(App),
    wait: new VueWait({
        useVuex: true
    })
}).$mount('#app')

export default app;
