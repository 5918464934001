<template>
    <div class="input-group">
        <input type="text" :class="['form-control', errors.length ? 'is-invalid' : null]" :placeholder="placeholder" :disabled="disabled" :readonly="readonly" :value="value">
        <div class="input-group-append datepickerbutton">
            <span class="input-group-text"><i class="fa fa-calendar"></i></span>
        </div>
        <div class="invalid-feedback text-left">
            {{ errors.length ? errors[0] : null }}
        </div>
    </div>
</template>

<script>
    import jQuery from 'jquery'
    import moment from 'moment'
    import 'eonasdan-bootstrap-datetimepicker'

    export default {
        props: {
            value: {
                validator(value) {
                    return value === null || typeof value === 'string'
                }
            },

            placeholder: {
                type: String,
                default: null
            },

            errors: {
                type: Array,
                default: () => ([])
            },

            minDate: {
                validator(v) {
                    return typeof v === 'string' || v === null
                }
            },

            disabled: {
                type: Boolean,
                default: false
            },

            readonly: {
                type: Boolean,
                default: false
            },

            seenOnTop: {
                type: Boolean,
                default: false
            }
        },

        data: () => ({
            elem: null,
            dp: null
        }),

        methods: {
            onChange(event) {
                let format = event.date ? event.date.format('DD/MM/YYYY') : null
                this.$emit('change', format)
                this.$emit('input', format)
            }
        },

        mounted() {
            this.elem = jQuery(this.$el)
            this.elem.datetimepicker({
                format: 'DD/MM/YYYY',
                minDate: this.minDate ? this.minDate : moment(),
                defaultDate: this.minDate ? this.minDate : moment(),
                ignoreReadonly: true,
                allowInputToggle: true,
                widgetPositioning: {
                    vertical: this.seenOnTop ? 'top' : 'bottom',
                    horizontal: 'left'
                },
                icons: {
                    up: 'fa fa-chevron-up',
                    down: 'fa fa-chevron-down',
                    previous: 'fa fa-chevron-left',
                    next: 'fa fa-chevron-right'
                }
            })

            this.dp = this.elem.data('DateTimePicker')
            this.dp.date(moment(this.value, 'DD/MM/YYYY'))
            this.elem.on('dp.change', this.onChange)

            let format = this.dp.date() ? this.dp.date().format('DD/MM/YYYY') : null
            this.$emit('change', format)
            this.$emit('input', format)
        },

        beforeDestroy() {
            if (this.dp) {
                this.dp.destroy()
                this.dp = null
                this.elem = null
            }
        }
    }
</script>
