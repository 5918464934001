import axios from 'axios'
import moment from 'moment'
import { set } from 'lodash'

const SET_POPULATED_USERS = 'SET_POPULATED_USERS'
const SET_IMPERSONATION_LINK = 'SET_IMPERSONATION_LINK'

const SET_USER = 'SET_USER'
const CLEAR_USER = 'CLEAR_USER'

const SET_LOADING_FLAG = 'SET_LOADING_FLAG'
const CLEAR_LOADING_FLAG = 'CLEAR_LOADING_FLAG'

const SET_FORM_ERRORS = 'SET_FORM_ERRORS'
const CLEAR_FORM_ERRORS = 'CLEAR_FORM_ERRORS'

const INPUT_CHANGED = 'INPUT_CHANGED'

/**
 * Vuex state for auth module
 * @type {Object}
 */
const state = {
    loadingFlag: false,
    populatedUsers: {},
    impersonationLink: {},
    user: {},
    errors: {},
    dataTableColumns: [
        'first_name',
        'username',
        'mobile',
        'email',
        'lifebox_email',
        'account_number',
        'user_type',
        'user_status',
        'created_at'
    ],
    userStatuses: [
        "active",
        "inactive",
        "subscribed",
        "un_subscribed"
    ],
    userTypes: [
        "user",
        "administrator",
        "general"
    ]
}

/**
 * Vuex mutations for auth module
 * @type {Object}
 */
const mutations = {
    [SET_POPULATED_USERS]: (state, payload) => {
        state.populatedUsers = payload
    },

    [SET_IMPERSONATION_LINK]: (state, payload) => {
        state.impersonationLink = payload
    },

    [SET_USER]: (state, payload) => {
        state.user = payload
    },

    [CLEAR_USER]: (state) => {
        state.user = {}
    },

    [SET_FORM_ERRORS]: (state, payload) => {
        state.errors = payload
    },

    [CLEAR_FORM_ERRORS]: (state) => {
        state.errors = {}
    },

    [SET_LOADING_FLAG]: (state, payload) => {
        state.loadingFlag = payload
    },

    [CLEAR_LOADING_FLAG]: (state) => {
        state.loadingFlag = {}
    },

    [INPUT_CHANGED]: (state, { key, value }) => {
        const stack = Object.assign({}, state.user)
        if (key) set(stack, key, value)
        state.user = stack
    },
}

/**
 * Vuex actions for auth module
 * @type {Object}
 */
const actions = {
    async populateUsers({ commit }, payload) {
        try {
            const { data } = await axios.get('/api/users', {
                params: payload
            })
            commit(SET_POPULATED_USERS, data)
        } catch (e) {
            console.error(e)
        }
    },

    async impersonateUser({ commit }, { id }) {
        try {
            const { data } = await axios.get(`/api/users/${id}/impersonate`)
            commit(SET_IMPERSONATION_LINK, data)
        } catch (e) {
            console.error(e)
        }
    },

    inputChanged({ commit }, payload) {
        commit(INPUT_CHANGED, payload)
    },

    async setUser({ commit }, userID) {
        try {
            const { data } = await axios.get(`/api/users/${userID}`)
            commit(SET_USER, data)
        } catch (e) {
            console.error(e)
        }
    },

    async removeUser({ commit }, userID) {
        try {
            await axios.delete(`/api/users/${userID}`)
            commit(CLEAR_USER)
        } catch (e) {
            console.error(e)
        }
    },

    async clearUser({ commit }) {
        commit(CLEAR_USER)
        commit(CLEAR_FORM_ERRORS)
    },

    async updateUser({ commit, state }, { onSuccess, onFailed }) {
        commit(SET_LOADING_FLAG, true)

        try {
            const { user } = state
            const { data } = await axios.patch(`/api/users/${user.id}`, { ...user })
            if (data) commit(SET_USER, data)
            if (onSuccess instanceof Function) onSuccess(data)
        } catch (error) {
            if (error.response.status === 422) commit(SET_FORM_ERRORS, error.response.data.errors)
            if (onFailed instanceof Function) onFailed(error)
        }

        commit(SET_LOADING_FLAG, false)
    },

    async createUser({ commit, state }, { onSuccess, onFailed }) {
        commit(SET_LOADING_FLAG, true)

        try {
            const { user } = state
            const { data } = await axios.post(`/api/users`, { ...user })
            if (data) commit(SET_USER, data)
            if (onSuccess instanceof Function) onSuccess(data)
        } catch (error) {
            if (error.response.status === 422) commit(SET_FORM_ERRORS, error.response.data.errors)
            if (onFailed instanceof Function) onFailed(error)
        }

        commit(SET_LOADING_FLAG, false)
    }
}

/**
 * Vuex getters for auth module
 * @type {Object}
 */
const getters = {
    populatedUsers: ({ populatedUsers }) => {
        const { data } = populatedUsers
        const items = []

        if (data.length) {
            data.forEach(user => {
                items.push([
                    user.first_name + ' ' + user.last_name,
                    user.username,
                    user.mobile,
                    user.email,
                    user.lifebox_email,
                    user.account_number,
                    user.user_type,
                    user.user_status,
                    moment(user.created_at).format('dddd, MMMM D, YYYY - h:mm A'),
                    user.id
                ])
            })
        }

        return items
    },
    populatedUsersCount: state => state.populatedUsers.total,
    dataTableColumns: state => state.dataTableColumns,
    user: state => state.user,
    errors: state => state.errors,
    loadingFlag: state => state.loadingFlag,
    userStatuses: state => state.userStatuses,
    userTypes: state => state.userTypes,
    impersonationLink: ({ impersonationLink }) => impersonationLink.redirect + '?' + impersonationLink.token
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
