<template>
    <div class="nav-item">
        <div @click="tabs.onSelect(value)" :class="['nav-link', isActive ? `active ${active}` : null]">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                validator(v) {
                    return v === null || typeof v === 'string'
                }
            },

            active: {
                type: String,
                default: ''
            }
        },

        inject: ['tabs'],

        computed: {
            isActive() {
                return this.value === this.tabs.active
            }
        }
    }
</script>

<style lang="scss" scoped>
    .nav-link { cursor: pointer; }
</style>
