<template>
    <div :class="['nav nav-tabs nav-fill', classes]" role="tablist">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                validator(v) {
                    return v === null || typeof v === 'string'
                }
            },

            classes: {
                type: String
            }
        },

        provide() {
            const tabs = {
                onSelect: this.onTabSelect
            }

            Object.defineProperty(tabs, 'active', {
                enumerable: true,
                get: () => this.value
            })

            return { tabs }
        },

        methods: {
            onTabSelect(tab) {
                this.$emit('input', tab)
                this.$emit('change', tab)
            }
        },
    }
</script>
