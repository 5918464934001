<template>
    <div :class="['avatar-upload-input', classes]" :style="{ width, height }">
        <div class="preview">
            <img class="img-thumbnail" :src="previewImage">
        </div>

        <Button size="md" :color="buttonColor" :classes="`mt-2 text-${fontColor}`" @click.native="$refs.fileinput.click()">
            <i class="fa fa-camera"></i> Select Photo
        </Button>

        <input ref="fileinput" type="file" @change="onSelectImage" accept="image/*">
    </div>
</template>

<script>
    const host = process.env.VUE_APP_API_HOST || ''

    export default {
        props: {
            value: {
                validator(v) {
                    return typeof v === 'object'
                        || typeof v === 'string'
                        || v === null
                }
            },

            classes: {
                type: String,
                default: ''
            },

            width: {
                type: String,
                default: '100%'
            },

            height: {
                type: String,
                default: '100%'
            },

            fontColor: {
                type: String,
                default: 'light'
            },

            buttonColor: {
                type: String,
                default: 'bidcrete-green'
            }
        },

        data: () => ({
            image: null,
        }),

        computed: {
            previewImage() {
                return this.value === null
                    ? `${host}/assets/sample.jpg` : typeof this.value === 'string'
                    ? this.value : this.image
            }
        },

        methods: {
            onSelectImage(event) {
                const input = event.target
                if (input.files && input.files[0]) {
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        this.image = e.target.result
                        this.$emit('input', input.files[0])
                        this.$emit('change', input.files[0])
                    }
                    reader.readAsDataURL(input.files[0])
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .avatar-upload-input {
        text-align: center;
        .preview {
            img {
                max-width: 100%;
                max-height: 100%;
                min-height: 100%;
                min-width: 100%;
            }
        }

        input[type="file"] {
            display: none;
        }
    }
</style>
