<template>
    <div style="overflow:hidden;">
        <div id="calendar"></div>
    </div>
</template>

<script>
    import jQuery from 'jquery'
    import moment from 'moment'
    import 'eonasdan-bootstrap-datetimepicker'

    export default {
        props: {
            value: {
                validator(value) {
                    return value === null || typeof value === 'string'
                }
            },

            placeholder: {
                type: String,
                default: null
            },

            minDate: {
                validator(v) {
                    return typeof v === 'string' || v === null
                }
            }
        },

        data: () => ({
            elem: null,
            dp: null
        }),

        methods: {
            onChange(event) {
                let format = event.date ? event.date.format('DD/MM/YYYY') : null
                this.$emit('change', format)
                this.$emit('input', format)
            }
        },

        mounted() {
            this.elem = jQuery(this.$el)
            this.elem.datetimepicker({
                format: 'DD/MM/YYYY',
                minDate: this.minDate ? this.minDate : moment(),
                defaultDate: this.minDate ? this.minDate : moment(),
                inline: true,
                sideBySide: true,
                icons: {
                    up: 'fa fa-chevron-up',
                    down: 'fa fa-chevron-down',
                    previous: 'fa fa-chevron-left',
                    next: 'fa fa-chevron-right'
                }
            })

            this.dp = this.elem.data('DateTimePicker')
            this.dp.date(moment(this.value, 'DD/MM/YYYY'))
            this.elem.on('dp.change', this.onChange)

            let format = this.dp.date() ? this.dp.date().format('DD/MM/YYYY') : null
            this.$emit('change', format)
            this.$emit('input', format)
        },

        beforeDestroy() {
            if (this.dp) {
                this.dp.destroy()
                this.dp = null
                this.elem = null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bootstrap-datetimepicker-widget table th {
        height: 10vw;
        line-height: 10vw;
    }

    .bootstrap-datetimepicker-widget table td.day {
        height: 10vw !important;
        line-height: 10vw !important;
        width: 10vw !important;
    }
</style>
