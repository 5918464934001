var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['form-group', { clearable: _vm.clearable }]},[(_vm.label)?_c('label',{class:[{ 'font-weight-bold': _vm.labelBold }],attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.inputGroupOne || _vm.inputGroupTwo)?_c('div',{staticClass:"input-group mb-2"},[(_vm.inputGroupOne)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{class:['input-group-text', { 'font-weight-bold': _vm.inputGroupFontBold }]},[_vm._v(_vm._s(_vm.inputGroupOne))])]):_vm._e(),_c('input',_vm._b({class:[
                _vm.classes,
                'form-control',
                { 'is-invalid': _vm.errors.length, 'filled': _vm.value, 'hide-invalid-icon': _vm.hideInvalidIcon }
            ],attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"id":_vm.id,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete || 'nope',"step":_vm.numFormat,"min":"0"},domProps:{"value":_vm.value},on:{"input":_vm.onInput}},'input',Object.assign({}, _vm.$attrs, _vm.$props),false)),(_vm.inputGroupTwo)?_c('div',{staticClass:"input-group-append"},[_c('span',{class:['input-group-text', { 'font-weight-bold': _vm.inputGroupFontBold }]},[_vm._v(_vm._s(_vm.inputGroupTwo))])]):_vm._e(),_vm._t("hint"),_c('div',{staticClass:"invalid-feedback text-left"},[_vm._v(_vm._s(_vm.errors.length ? _vm.errors[0] : null))])],2):[_c('input',_vm._b({class:[
                _vm.classes,
                'form-control rounded-10',
                { 'is-invalid': _vm.errors.length, 'filled': _vm.value, 'hide-invalid-icon': _vm.hideInvalidIcon }
            ],attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"id":_vm.id,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete || 'nope',"step":_vm.numFormat,"min":"0"},domProps:{"value":_vm.value},on:{"input":_vm.onInput}},'input',Object.assign({}, _vm.$attrs, _vm.$props),false)),_vm._t("hint"),_c('div',{staticClass:"invalid-feedback text-left"},[_vm._v(_vm._s(_vm.errors.length ? _vm.errors[0] : null))])],(_vm.clearable)?_c('i',{staticClass:"fa fa-times",on:{"click":function($event){return _vm.$emit('clear')}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }