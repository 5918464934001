import Cookies from 'js-cookie'
import axios from 'axios'
import router from '@/router'

const SET_TOKEN = 'SET_TOKEN'
const SET_USER = 'SET_USER'
const LOGOUT = 'LOGOUT'

/**
 * Vuex state for auth module
 * @type {Object}
 */
const state = {
    user: null,
    token: Cookies.get('token') || null,
}

/**
 * Vuex mutations for auth module
 * @type {Object}
 */
const mutations = {
    [SET_TOKEN]: (state, { token }) => {
        state.token = token
        Cookies.set('token', token)
    },

    [SET_USER]: (state, payload) => {
        state.user = payload
    },

    [LOGOUT]: (state) => {
        Cookies.remove('token')

        state.token = null
        state.user = null
    }
}

/**
 * Vuex actions for auth module
 * @type {Object}
 */
const actions = {
    async fetchUser({ commit, dispatch }) {
        try {
            const { data } = await axios.get('/api/me')
            commit(SET_USER, data)
        } catch (e) {
            dispatch('logout')
        }
    },

    async login({ dispatch }, payload) {
        const { data } = await axios.post('/api/login', payload)
        
        if (data.accessToken) {
            dispatch('setToken', { token: data.accessToken })
            return { loggedIn: true }
        } else {
            return { loggedIn: false }
        }
    },

    async logout({ commit }) {
        try {
            await axios.post('/api/logout')
        } finally {
            commit(LOGOUT)
            router.push({ name: 'login' })
        }
    },

    setToken({ commit }, payload) {
        commit(SET_TOKEN, payload)
    },
}

/**
 * Vuex getters for auth module
 * @type {Object}
 */
const getters = {
    token: state => state.token,
    user: state => state.user,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
