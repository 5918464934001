<template>
    <div :class="['tab-pane fade', isActive ? 'show active' : null]" role="tabpanel">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: ['value'],

        inject: ['tabs'],

        computed: {
            isActive() {
                return this.tabs.active === this.value
            }
        }
    }
</script>
