<template>
    <div :class="['form-group', { clearable }]">
        <label :for="id" v-if="label" :class="[{ 'font-weight-bold': labelBold }]">{{ label }}</label>

        <div class="input-group mb-2" v-if="inputGroupOne || inputGroupTwo">
            <div class="input-group-prepend" v-if="inputGroupOne">
                <span :class="['input-group-text', { 'font-weight-bold': inputGroupFontBold }]">{{ inputGroupOne }}</span>
            </div>

            <input
                :value="value"
                :type="type"
                v-bind="{...$attrs, ...$props}"
                :class="[
                    classes,
                    'form-control',
                    { 'is-invalid': errors.length, 'filled': value, 'hide-invalid-icon': hideInvalidIcon }
                ]"
                :placeholder="placeholder"
                @input="onInput"
                :id="id"
                :readonly="readonly"
                :autocomplete="autocomplete || 'nope'"
                :step="numFormat"
                min="0"
            >

            <div class="input-group-append" v-if="inputGroupTwo">
                <span :class="['input-group-text', { 'font-weight-bold': inputGroupFontBold }]">{{ inputGroupTwo }}</span>
            </div>
            <slot name="hint"></slot>
            <div class="invalid-feedback text-left">{{ errors.length ? errors[0] : null }}</div>
        </div>
        <template v-else>
            <input
                :value="value"
                :type="type"
                v-bind="{...$attrs, ...$props}"
                :class="[
                    classes,
                    'form-control rounded-10',
                    { 'is-invalid': errors.length, 'filled': value, 'hide-invalid-icon': hideInvalidIcon }
                ]"
                :placeholder="placeholder"
                @input="onInput"
                :id="id"
                :readonly="readonly"
                :autocomplete="autocomplete || 'nope'"
                :step="numFormat"
                min="0"
            >

            <slot name="hint"></slot>
            <div class="invalid-feedback text-left">{{ errors.length ? errors[0] : null }}</div>
        </template>

        <i class="fa fa-times" v-if="clearable" @click="$emit('clear')"></i>
    </div>
</template>

<script>
    export default {
        props: {
            hideInvalidIcon: {
                type: Boolean,
                default: false
            },

            value: {
                validator(v) {
                    return v === null
                        || typeof v === 'number'
                        || typeof v === 'string'
                }
            },

            type: {
                type: String,
                default: 'text'
            },

            placeholder: {
                type: String
            },

            errors: {
                type: Array,
                default: () => ([])
            },

            label: {
                type: String,
                default: null
            },

            classes: {
                type: String,
                default: ''
            },

            inputGroupOne: {
                type: String,
                default: ''
            },

            inputGroupTwo: {
                type: String,
                default: ''
            },

            readonly: {
                type: Boolean,
                default: false
            },

            clearable: {
                type: Boolean,
                default: false
            },

            autocomplete: {
                type: Boolean,
                default: false
            },

            numFormat: {
                type: String,
                default: '1'
            },

            labelBold: {
                type: Boolean,
                default: false
            },

            inputGroupFontBold: {
                type: Boolean,
                default: false
            }
        },

        data: () => ({
            id: null
        }),

        methods: {
            onInput(v) {
                this.$emit('input', v.target.value)
                this.$emit('change', v.target.value)
            },

            focus() {
                this.$el.querySelector('input').focus()
            }
        },

        beforeMount() {
            this.id = Math.random().toString(36).substring(length)
        }
    }
</script>

<style lang="scss" scoped>
    .form-group {
        position: relative;
        i.fa {
            position: absolute;
            right: 0;
            top: 0;
            color: red;
            padding: 0 10px;
            line-height: 36px;
        }

        &.clearable .form-control {
            padding-right: 28px;
        }

        .form-control {
            &.hide-invalid-icon {
                &.is-invalid,
                &:invalid {
                    background: white;
                }
            }
        }
    }
</style>
