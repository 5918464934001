<template>
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-lifebox sidebar sidebar-dark accordion" id="accordionSidebar">

        <!-- Sidebar - Brand -->
        <router-link 
            tag="a" 
            class="sidebar-brand d-flex align-items-center justify-content-center" 
            :to="{ name: 'home' }" 
        >
            <div class="sidebar-brand-icon">
                <img src="@/assets/img/logos/logo.png" height="20" width="85">
            </div>
            <div class="sidebar-brand-text mx-3 mt-1">Admin</div>
        </router-link>

        <!-- Heading -->
        <div class="sidebar-heading">
            MAIN MENU
        </div>

        <li class="nav-item">
            <router-link tag="a" class="nav-link" :to="{ name: 'home' }">
                <i class="fa fa-home ml-1 mr-2"></i>
                <span>Home</span>
            </router-link>
        </li>

        <li class="nav-item">
            <router-link tag="a" class="nav-link" :to="{ name: 'users' }">
                <i class="fa fa-usd ml-1 mr-2 pr-1"></i>
                <span>Users</span>
            </router-link>
        </li>

        <li class="nav-item">
            <router-link tag="a" class="nav-link" :to="{ name: 'retailers' }">
                <i class="fa fa-globe ml-1 mr-2"></i>
                <span>Retailers</span>
            </router-link>
        </li>

        <li class="nav-item">
            <router-link tag="a" class="nav-link" :to="{ name: 'notifications' }">
                <i class="fa fa-bell ml-1 mr-2"></i>
                <span>Notifications</span>
            </router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider">

        <!-- Heading -->
        <div class="sidebar-heading">
            ACCOUNT
        </div>

        <li class="nav-item">
            <router-link tag="a" class="nav-link" :to="{ name: 'settings' }">
                <i class="fa fa-cog ml-1 mr-2"></i>
                <span>Settings</span>
            </router-link>
        </li>

        <li class="nav-item">
            <a 
                href="#" 
                class="nav-link" 
                @click="() => { $store.dispatch('auth/logout') }"
            >
                <i class="fa fa-sign-out ml-1 mr-2"></i>
                <span>Logout</span>
            </a>
        </li>

    </ul>
    <!-- End of Sidebar -->
</template>

<script>
    export default {
        name: "SideBarMenu"
    }
</script>
