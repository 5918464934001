<style>
    .img-size img {
        max-width: 100%;
    }
</style>

<template>
    <div class="row mb-4">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12 img-size text-center">
            <img v-bind:src="imagePreview"/>
            <!-- <button v-on:click="submitFile()">Submit</button> -->
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12 text-center">
            <input class="my-5 bg-light-grey small" type="file" id="file" ref="file" accept="image/*" v-on:change="handleFileUpload()"/>
        </div>
    </div>
</template>

<script>
    export default {
    /*
        Defines the data used by the component
    */
    data(){
        return {
            file: '',
            imagePreview: require('@/assets/img/sample.jpg')
            }
        },

    methods: {
        /*
            Submits the file to the server
        */
        submitFile(){
            /*
            initialize the form data
            */
            let formData = new FormData();

            /*
            readAsDataURLdd the form data we need to submit
            */
            formData.append('file', this.file);

        },

        /*
            Handles a change on the file upload
        */
        handleFileUpload(){
            /*
                Set the local file variable to what the user has selected.
            */
            this.file = this.$refs.file.files[0];

            /*
                Initialize a File Reader object
            */
            let reader  = new FileReader();

            /*
                Add an event listener to the reader that when the file
                has been loaded, we flag the show preview as true and set the
                image to be what was read from the reader.
            */
            reader.addEventListener("load", function () {
            this.showPreview = true;
            this.imagePreview = reader.result;
            }.bind(this), false);

            /*
                Check to see if the file is not empty.
            */
            if( this.file ){
                /*
                    Ensure the file is an image file.
                */
                if ( /\.(jpe?g|png|gif)$/i.test( this.file.name ) ) {
                    /*
                        Fire the readAsDataURL method which will read the file in and
                        upon completion fire a 'load' event which we will listen to and
                        display the image in the preview.
                    */
                    reader.readAsDataURL( this.file );
                }
            }
        }
    }
}
</script>
