import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios'

import auth from '@/stores/auth'
import user from '@/stores/user'
import retailer from '@/stores/retailer'

// import app from '@/main'

const allowedTypes = [
    'auth/SET_TOKEN',
    'auth/LOGOUT',
]

const setRequestHeaders = (state) => {
    const { token } = state.auth
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.common['Accept'] = 'application/json'
    // axios.interceptors.response.use(
    //     config => {
    //         app.$Progress.start()
    //         return config
    //     },
    //     response => {
    //         app.$Progress.finish()
    //         return Promise.resolve(response.data)
    //     },
    //     error => {
    //         app.$Progress.fail()
    //         return Promise.reject(error)
    //     }
    // )
}

const Plugins = {
    request: (store) => {
        setRequestHeaders(store.state)

        store.subscribe((mutation, state) => {
            if (allowedTypes.indexOf(mutation.type) !== -1) {
                setRequestHeaders(state)
            }
        })
    }
}

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        auth,
        user,
        retailer
    },
    plugins: [
        Plugins.request
    ]
})

export default store
